/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TCombinedFiltersType } from '@th-common/enums/status/operational-status.enum';
import {
  databaseDeviceFilterTitleToKind,
  IDatabaseDevice,
  IVehicleDatabaseFiltersFormValues,
  IVehicleDatabaseSearchRequest,
  TDatabaseDeviceFilterKind,
  TVehicleDatabaseFiltered,
} from '@th-common/interfaces/database.interface';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import dayjs from 'dayjs';

import { api } from './api';

export interface IVehicleDatabaseState {
  devices: IDatabaseDevice[];
  combinedFilter: TCombinedFiltersType;
  activeDeviceIndex: number | null;
  activeDeviceId: number | null;
  activeDevice: IDatabaseDevice | null;
  dataListLoading: boolean;
  searchRequest: IVehicleDatabaseSearchRequest;
  pagination: Omit<IPagedResult<IDatabaseDevice>, 'items'>;
  lastTimeUpdated: string;
  addDeviceOpen: boolean;
  editDeviceOpen: boolean;
}

export const defaultFilters: TVehicleDatabaseFiltered = {
  configGroupId: null,
  deviceFilterKind: TDatabaseDeviceFilterKind.All,
  orderBy: [
    {
      fieldName: 'id',
      ascending: true,
    },
  ],
};

const initialState: IVehicleDatabaseState = {
  devices: [],
  combinedFilter: 'All',
  activeDevice: null,
  activeDeviceId: null,
  activeDeviceIndex: null,
  dataListLoading: false,
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    ...defaultFilters,
  },
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  addDeviceOpen: false,
  editDeviceOpen: false,
};

function convertFormValueToSearchRequestFilters(formValues: IVehicleDatabaseFiltersFormValues): TVehicleDatabaseFiltered {
  return {
    deviceFilterKind: ['All', 'New'].includes(formValues.configGroupId as string)
      ? databaseDeviceFilterTitleToKind[formValues.configGroupId]
      : TDatabaseDeviceFilterKind.ConfigGroup,
    configGroupId: formValues.configGroupId === 'All' || formValues.configGroupId === 'New'
      ? null
      : formValues.configGroupId,
    orderBy: [
      {
        fieldName: 'id',
        ascending: formValues.orderByDirection === 'asc',
      },
    ],
  };
}

export const slice = createSlice({
  name: 'vehicleDatabase',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilterDevices: (state, { payload }) => {
      state.combinedFilter = payload.combinedFilter;
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(payload),
      };
    },
    setDataListLoading: (state, { payload }: { payload: boolean }) => {
      state.dataListLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setActiveDeviceIndex: (state, { payload: deviceIndex }: { payload: number | null }) => {
      if (deviceIndex === null) {
        state.activeDevice = null;
        state.activeDeviceId = null;
        state.activeDeviceIndex = null;
      } else {
        const device = state.devices[deviceIndex];

        if (device) {
          state.activeDevice = device;
          state.activeDeviceId = device.id;
          state.activeDeviceIndex = deviceIndex;
        }
      }
    },
    openAddDevice: (state) => {
      state.addDeviceOpen = true;
    },
    closeAddDevice: (state) => {
      state.addDeviceOpen = false;
    },
    openEditDevice: (state) => {
      state.editDeviceOpen = true;
    },
    closeEditDevice: (state) => {
      state.editDeviceOpen = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVehicleDatabaseState>) => {
    builder
      .addMatcher(api.endpoints.getDatabaseDevices.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;
        state.devices = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
