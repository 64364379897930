import { IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { IGpsDataParams, TGpsAPIData } from '@th-common/interfaces/video/gps-data';
import { apiRoot } from '@th-common/store/api';
import { DateTimeUtils } from '@th-common/utils';

import { startTime } from './slice';

interface IVideoPlayback {
  id: number;
  start: string;
  end: string;
  cameras: IVideoSourceInfo[];
}

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getVideo: builder.query<IVideoPlayback, number>({
      query: (videoQueryId) => `video-query/${videoQueryId}`,
    }),
    getGPSData: builder.query<TGpsAPIData[], IGpsDataParams>({
      // query: ({
      //   startTime,
      //   endTime,
      // }) => {
      //   const fpsDateFormat = 'YYYYMMDDTHHmmss';
      //   const gpsStartTime = DateTimeUtils.noTimeZoneDateTime(startTime, fpsDateFormat);
      //   const gpsEndTime = DateTimeUtils.noTimeZoneDateTime(endTime, fpsDateFormat);

      //   return {
      //     url: `data/${gpsStartTime}_${gpsEndTime}.json`,
      //     method: 'GET',
      //   };
      // },
      queryFn: () => {
        return new Promise((resolve) => {
          resolve({
            data: [
              `${DateTimeUtils.noTimeZoneDateTime(startTime)};33.15964;-117.15239;218.1;28.0;350.0`,
              `${DateTimeUtils.noTimeZoneDateTime(startTime.add(5, 's'))};33.16017;-117.15245;224.5;29.0;354.6`,
              `${DateTimeUtils.noTimeZoneDateTime(startTime.add(10, 's'))};33.16029;-117.1525;224.5;27.0;340.8`,
              `${DateTimeUtils.noTimeZoneDateTime(startTime.add(15, 's'))};33.1604;-117.15259;227.3;23.0;325.6`,
              `${DateTimeUtils.noTimeZoneDateTime(startTime.add(20, 's'))};33.16082;-117.1532;231.7;28.0;309.4`,
              `${DateTimeUtils.noTimeZoneDateTime(startTime.add(25, 's'))};33.16124;-117.15381;228.4;26.0;309.4`,
            ],
          });
        });
      },
    }),
  }),
});
