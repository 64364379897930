export interface IWrapperPlayerRefMethods {
  playVideo: () => void;
  reversePlayVideo: () => void;
  pauseVideo: () => void;
  stepNextFrame: () => void;
  stepPreviousFrame: () => void;
  setCurrentTime: (time: number) => void;
  setPlaybackRate: (playbackRate: number) => void;
  getCurrentPlaybackRate: () => number;
}

export interface IPlayerRefParams {
  player: HTMLVideoElement;
  fps: number;

  get canPlay(): boolean;
  set canPlay(value: boolean);

  get isInitialized(): boolean;
  set isInitialized(value: boolean);
}

export enum TViewLayout {
  Grid = 'grid',
  List = 'list',
}
