import { databaseApiEndpoints } from '@th-common/api/database.api';
import { IDatabaseDevice, IVehicleDatabaseSearchRequest } from '@th-common/interfaces/database.interface';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDatabaseDevices: builder.query<IPagedResult<IDatabaseDevice>, IVehicleDatabaseSearchRequest>(
      databaseApiEndpoints.getDatabaseDevices,
    ),
  }),
});
