import { TDatabaseDeviceState } from '@th-common/enums/database/vehicle-database-state.enum';
import { IBaseSearchRequest, IOrderBy } from '@th-common/interfaces/search-request';

export enum TDatabaseDeviceFilterKind {
  All = 1,
  New = 4,
  ConfigGroup = 3,
}

export type TDatabaseDeviceFilterValue = 'All' | 'New' | number;

export interface IDatabaseDevice {
  id: number;
  name: string;
  serialNumber: string;
  deviceType: string;
  connectionAddress: string;
  connectionPort: number;
  description: string;
  cellularAddress: string;
  cellularPort: number | null;
  state: TDatabaseDeviceState;
}

export const databaseDeviceFilterTitleToKind: Record<string, TDatabaseDeviceFilterKind> = {
  All: TDatabaseDeviceFilterKind.All,
  New: TDatabaseDeviceFilterKind.New,
  ConfigGroup: TDatabaseDeviceFilterKind.ConfigGroup,
};

export const databaseDeviceFilterKindToFilterValue:
Record<TDatabaseDeviceFilterKind.All, 'All'> & Record<TDatabaseDeviceFilterKind.New, 'New'> = {
  [TDatabaseDeviceFilterKind.All]: 'All',
  [TDatabaseDeviceFilterKind.New]: 'New',
};

export interface IVehicleDatabaseSearchRequest extends IBaseSearchRequest, IVehicleDatabaseFilters {
}

export interface IVehicleDatabaseFilters {
  deviceFilterKind: TDatabaseDeviceFilterKind;
  configGroupId: null | number;
}

export type TVehicleDatabaseFiltered = IVehicleDatabaseFilters & { orderBy: IOrderBy[] };

export interface IVehicleDatabaseFiltersFormValues {
  configGroupId: TDatabaseDeviceFilterValue;
  combinedFilter: 'All' | string;
  orderByDirection: 'asc' | 'desc';
}

export interface IDatabaseAddDeviceForm extends Omit<IDatabaseDevice, 'state' | 'id'> {
  id: number | null;
  isCellular: boolean;
}

export interface ISetDatabaseDeviceStateRequest {
  id: number;
  state: TDatabaseDeviceState.New | TDatabaseDeviceState.Active | TDatabaseDeviceState.Inactive;
}
