import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import {
  IDatabaseAddDeviceForm,
  ISetDatabaseDeviceStateRequest,
  IVehicleDatabaseSearchRequest,
} from '@th-common/interfaces/database.interface';
import { apiRoot } from '@th-common/store/api';

export const databaseApiEndpoints = {
  getDatabaseDevices: {
    query: (searchRequest: IVehicleDatabaseSearchRequest) => {
      return {
        url: 'device/database-devices/all',
        method: 'POST',
        body: searchRequest,
      };
    },
    providesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
  addDatabaseDevices: {
    query: (data: IDatabaseAddDeviceForm) => {
      return {
        url: 'device/database-device',
        method: 'POST',
        body: data,
      };
    },
    invalidatesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
  editDatabaseDevices: {
    query: (data: IDatabaseAddDeviceForm) => {
      return {
        url: 'device/database-device',
        method: 'PUT',
        body: data,
      };
    },
    invalidatesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
  setDatabaseDeviceState: {
    query: (data: ISetDatabaseDeviceStateRequest) => ({
      url: 'device/database-device-state',
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
  deleteDatabaseDevice: {
    query: (deviceId: number) => ({
      url: `device/${deviceId}`,
      method: 'DELETE',
    }),
    invalidatesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
  decommissionDevice: {
    query: (deviceId: number) => ({
      url: `device/decommission-device?command=${deviceId}`,
      method: 'PUT',
    }),
    invalidatesTags: [
      TReduxTag.VehicleDatabase,
    ],
  },
};

export const databaseApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addDatabaseDevices: builder.mutation<void, IDatabaseAddDeviceForm>(databaseApiEndpoints.addDatabaseDevices),
    editDatabaseDevices: builder.mutation<void, IDatabaseAddDeviceForm>(databaseApiEndpoints.editDatabaseDevices),
    setDatabaseDeviceActive: builder.mutation<void, ISetDatabaseDeviceStateRequest>(databaseApiEndpoints.setDatabaseDeviceState),
    setDatabaseDeviceInactive: builder.mutation<void, ISetDatabaseDeviceStateRequest>(databaseApiEndpoints.setDatabaseDeviceState),
    deleteDatabaseDevice: builder.mutation<void, number>(databaseApiEndpoints.deleteDatabaseDevice),
    decommissionDevice: builder.mutation<void, number>(databaseApiEndpoints.decommissionDevice),
    reactivateDevice: builder.mutation<void, ISetDatabaseDeviceStateRequest>(databaseApiEndpoints.setDatabaseDeviceState),
  }),
});
