import { FC } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { isNaN, isNil } from 'lodash-es';
import { addMethod, array, object, string } from 'yup';

import { addDeviceRegexp } from '@/app/app/database/vehicle-database/modals/AddDevice/add-device-regexp.constant';

addMethod(object, 'dayjs', (message) => {
  return object().test('dayjs', message, (value: any): boolean => {
    if (!value) {
      return true;
    }
    return dayjs.isDayjs(value);
  });
});

addMethod(array, 'dayjs', (message) => {
  return array().test('dayjs', message, (value: Dayjs[] | undefined): boolean => {
    const [fromDate, toDate] = value as DateRange<Dayjs>;
    return dayjs.isDayjs(fromDate) && dayjs.isDayjs(toDate);
  });
});

addMethod(string, 'deviceAddress', (message) => {
  return string()
    .test('deviceAddress', message, (value: any, s): boolean => {
      if (!value) {
        return true;
      }
      // Check is IP address
      const valAsArr = (value ?? '').split('.');
      if (
        valAsArr.length === 4
        && valAsArr.every((val: string) => ((val === '' || isNil(val)) ? false : !isNaN(+val)))
      ) {
        return addDeviceRegexp.ip.test(value);
      }

      // Check is localhost
      if (value.startsWith('localhost')) {
        return addDeviceRegexp.localhost.test(value);
      }

      // Check is dns name
      return addDeviceRegexp.dns.test(value);
    });
});

const YupProvider: FC<unknown> = (): null => {
  return null;
};
export default YupProvider;
