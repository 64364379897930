import { defineAbility, PureAbility } from '@casl/ability';
import { TAuthFeature } from '@th-common/enums/auth-feature.enum';
import { TAuthMethod } from '@th-common/enums/auth-method.enum';
import { TLicensedFeature } from '@th-common/enums/licensed-feature.enum';
import { TPermissionAbilityAction, TPermissionAbilitySubject } from '@th-common/enums/permission-ability.enum';
import { MongoQuery } from '@ucast/mongo';

type Ability = [TPermissionAbilityAction, TPermissionAbilitySubject];
export type AppAbility = PureAbility<Ability, MongoQuery>;

export function defineAbilityFor(features: TAuthFeature[], lincensedFeatures: TLicensedFeature[], authMethod: TAuthMethod): AppAbility {
  return defineAbility<AppAbility>((can) => {
    can(TPermissionAbilityAction.View, TPermissionAbilitySubject.ByDefault);
    can(TPermissionAbilityAction.View, TPermissionAbilitySubject.DashboardPage);

    if (authMethod === TAuthMethod.Password && features.includes(TAuthFeature.ChangePassword)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.ChangePasswordPage);
    }

    if (features.includes(TAuthFeature.ViewFleetStatus)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.FleetStatusPage);
    }

    if (features.includes(TAuthFeature.ViewJobs)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.Jobs);
    }

    if (features.includes(TAuthFeature.AddEditJobs)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.Jobs);
    }

    if (features.includes(TAuthFeature.DeleteJobs)) {
      can(TPermissionAbilityAction.Delete, TPermissionAbilitySubject.Jobs);
    }

    if (features.includes(TAuthFeature.ViewVideoQueries)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.VideoQueryJob);
    }

    if (features.includes(TAuthFeature.RequestVideoQueries)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.VideoQueryJob);
    }

    if (features.includes(TAuthFeature.DeleteVideoQueries)) {
      can(TPermissionAbilityAction.Delete, TPermissionAbilitySubject.VideoQueryJob);
    }

    if (lincensedFeatures.includes(TLicensedFeature.GEO_QUERY) && features.includes(TAuthFeature.ViewJobs)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.GeographicalQueryJob);
    }

    if (lincensedFeatures.includes(TLicensedFeature.GEO_QUERY) && features.includes(TAuthFeature.GeographicalQueries)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.GeographicalQueryJob);
    }

    if (features.includes(TAuthFeature.RidershipValidation)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.RidershipValidationJob);
    }

    if (lincensedFeatures.includes(TLicensedFeature.RIDERSHIP_VALIDATION) && features.includes(TAuthFeature.RidershipValidation)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.RidershipValidationJob);
    }

    if (lincensedFeatures.includes(TLicensedFeature.RIDERSHIP_VALIDATION) && features.includes(TAuthFeature.ViewVideoQueries)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.RidershipJob);
    }

    if (features.includes(TAuthFeature.ServiceViewStatus)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.StatusUpdateJob);
    }

    if (features.includes(TAuthFeature.ServiceViewLog)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.LogbookUpdateJob);
    }

    if (features.includes(TAuthFeature.ViewStoredImages)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.EventDownloadJob);
    }

    if (features.includes(TAuthFeature.ServiceSyncTime)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.TimeSyncJob);
    }

    if (features.includes(TAuthFeature.ApplyConfig)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.SyncConfigurationJob);
    }

    if (features.includes(TAuthFeature.ServiceUpdateFirmware)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.FirmwareUpdateJob);
    }

    if (features.includes(TAuthFeature.AcceptRejectVerificationImage)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.CameraVerificationJob);
    }

    if (features.includes(TAuthFeature.ViewFleetDatabase)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.Database);
    }

    if (
      features.includes(TAuthFeature.ViewStoredImages)
      || features.includes(TAuthFeature.ViewLiveImages)
      || features.includes(TAuthFeature.VideoQueries)
    ) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.VideoPlayback);
    }

    if (features.includes(TAuthFeature.ViewService)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.Service);
    }

    if (features.includes(TAuthFeature.EditUsers) || features.includes(TAuthFeature.EditRoles)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.ManageUsers);
    }

    if (features.includes(TAuthFeature.SetReferenceImage)) {
      can(TPermissionAbilityAction.Set, TPermissionAbilitySubject.ReferenceImage);
    }

    if (features.includes(TAuthFeature.AcceptRejectVerificationImage)) {
      can(TPermissionAbilityAction.AcceptReject, TPermissionAbilitySubject.VerificationImage);
    }

    if (lincensedFeatures.includes(TLicensedFeature.CELLULAR)) {
      can(TPermissionAbilityAction.View, TPermissionAbilitySubject.NetworkScope);
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.AddDeviceCellular);
    }

    if (
      lincensedFeatures.includes(TLicensedFeature.CELLULAR)
      && features.includes(TAuthFeature.OverrideJobNetworkScope)
    ) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.NetworkScope);
    }

    if (lincensedFeatures.includes(TLicensedFeature.CELLULAR) && features.includes(TAuthFeature.ChangeDownloadJobNetworkScope)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.DownloadNetworkScope);
    }

    if (features.includes(TAuthFeature.AddVehicleToFleet)) {
      can(TPermissionAbilityAction.Manage, TPermissionAbilitySubject.Database);
    }

    if (features.includes(TAuthFeature.RemoveVehicleFromFleet)) {
      can(TPermissionAbilityAction.Delete, TPermissionAbilitySubject.Database);
    }
  });
}
